import React, {Component} from 'react';
import { L10n } from '@syncfusion/ej2-base';
import { Inject, Category, Tooltip as ChartTooltip, AccumulationTooltip, DataLabel, AccumulationDataLabel, Highlight, Legend, DateTime, AccumulationLegend, StripLine } from '@syncfusion/ej2-react-charts';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective } from '@syncfusion/ej2-react-charts';
import { AreaSeries, BarSeries, BubbleSeries, ColumnSeries, LineSeries, ParetoSeries, PieSeries, PolarSeries, PyramidSeries, RadarSeries, SplineAreaSeries, SplineSeries, StackingAreaSeries, StackingBarSeries, StackingColumnSeries, StackingLineSeries, WaterfallSeries } from '@syncfusion/ej2-react-charts';
import { PivotViewComponent, FieldList, Toolbar, PivotChart, ExcelExport, CalculatedField, ConditionalFormatting, PDFExport, NumberFormatting } from '@syncfusion/ej2-react-pivotview';
import { HeatMapComponent, Legend as HeatmapLegend, Tooltip as HeatmapTooltip, ILoadedEventArgs, HeatMapTheme, Adaptor } from '@syncfusion/ej2-react-heatmap';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import MiniTree from './MiniTree';
import MiniTable from './MiniTable';

// API
const API_settings = '/WebAppService/GetChartSetting';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class MiniChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      currentView: {},
      chart: {},
      availableChartModels: [],
      availableChartTypes: [],
      availableColumns: [],
      availableIndicators: [],
      availableRows: [],
      chartModelSelected: {},
      isLoading: false
    };

    // Data Structure
    this.getChartSetting = this.getChartSetting.bind(this);

    // Actions
    this.displayComponent = this.displayComponent.bind(this);
    this.displayChart = this.displayChart.bind(this);
    this.displayTable = this.displayTable.bind(this);
    this.applySettings = this.applySettings.bind(this);

    // Template
    this.templateChartAccumulation = this.templateChartAccumulation.bind(this);
    this.templateChartBar = this.templateChartBar.bind(this);
    this.templateChartBubble = this.templateChartBubble.bind(this);
    this.templateChartHeatmap = this.templateChartHeatmap.bind(this);
    this.templateChartPolar = this.templateChartPolar.bind(this);
    this.templateChartWidget = this.templateChartWidget.bind(this);
    this.templatePivot = this.templatePivot.bind(this);
  }
  
  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const chart = this.props.Chart;
    const isLoading = this.props.IsLoading;

    this.setState({ login, authId, language, itemId, itemType, itemTitle, blockType, currentView, chart, isLoading });
  }
  
  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const chart = this.props.Chart;
    const isLoading = this.props.IsLoading;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(JSON.stringify(this.props.Chart) !== JSON.stringify(prevProps.Chart)) {
      this.setState({ chart });
    }
    if(this.props.IsLoading !== prevProps.IsLoading) {
      this.setState({ isLoading });
    }
  }

  // Get Chart available Settings from the API
  async getChartSetting(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_settings, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetChartSettingResult;
      const chart = this.state.chart;

      if(result) {
        // Update Chart Settings
        chart.ChartSetting = result;

        const availableChartModels = result.AvailableChartModels;
        const availableChartTypes = result.AvailableChartTypes;
        const availableColumns = result.AvailableColumns;
        const availableIndicators = result.AvailableIndicators;
        const availableRows = result.AvailableRows;

        const chartModelSelected = result.ChartModel;

        this.setState({ chart, availableChartModels, availableChartTypes, availableColumns, availableIndicators, availableRows, chartModelSelected, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  displayComponent(chart) {
    if(chart.Mode === 'Chart') {
      return <div className="chartComponent">{this.displayChart(chart)}</div>;
    }
    else if(chart.Mode === 'Table') {
      return <div className="minichartTable">{this.displayTable(chart)}</div>;
    }
    else if(chart.Mode === 'Both') {
      return <div className=""></div>;
    }
  }

  displayChart(chart) {
    if(chart.Series && chart.Series.length > 0) {
      if((chart.ChartModel || chart.ChartSetting.ChartModel) && (chart.ChartModel.Name === chart.ChartSetting.ChartModel.Name)) {
        // Group Type Accumulation
        if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Accumulation') {
          return this.templateChartAccumulation(chart);
        }
        // Group Type Bar
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Bar') {
          // Group Type Heatmap
          if(chart.ChartSetting && chart.ChartSetting.ChartType.Name === 'Heatmap') {
            return this.templateChartHeatmap(chart);
          }
          else {
            return this.templateChartBar(chart);
          }
        }
        // Group Type Bubble
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Bubble') {
          return this.templateChartBubble(chart);
        }
        // Group Type Polar
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Polar') {
          return this.templateChartPolar(chart);
        }
        // Group Type Widget
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Widget') {
          return this.templateChartWidget(chart);
        }
      }
    }
  }

  displayTable(chart) {
    const { itemId, itemType, itemTitle, blockType } = this.state;

    if(chart.ChartTableView && chart.ChartTableView.ViewType === 0 && chart.ChartTableView.DefaultLevel === 0) {
      return <MiniTree ref={this.tree} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={true} CurrentView={chart.ChartTableView} Columns={chart.ChartTable.ColumnHeaders} Rows={chart.ChartTable.Rows}></MiniTree>;
    }
    else {
      return <MiniTable ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={true} CurrentView={chart.ChartTableView} Columns={chart.ChartTable.ColumnHeaders} Rows={chart.ChartTable.Rows}></MiniTable>;
    }
  }

  applySettings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Get Chart Settings
    this.getChartSetting(login, authId, itemId, itemType, blockType, currentView);
  }

  templateChartAccumulation(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, shared: true };

    return (
      <AccumulationChartComponent id={chart.Id} style={style} chartArea={chartArea} legendSettings={legendSettings} tooltip={tooltip} ref={chart => this.chart = chart}>
        <AccumulationSeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <AccumulationSeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={(serie.ChartType.Name === 'Pyramid' ? 'Pyramid' : 'Pie')} fill={serie.Color} dataLabel={{ name: 'Label', font: { fontWeight: serie.Marker.Label.Font.fontWeight, color: serie.Marker.Label.Font.Color }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible }} innerRadius={(serie.ChartType.Name === 'Doughnut' ? '40%' : '0%')} xName='X' yName='Y' pointColorMapping='Color' tooltipMappingName='X'></AccumulationSeriesDirective>
          })}
        </AccumulationSeriesCollectionDirective>
        <Inject services={[ AccumulationDataLabel, AccumulationLegend, AccumulationTooltip, PieSeries, PyramidSeries ]} />
      </AccumulationChartComponent>
    );
  }

  templateChartBar(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    let primaryXAxis, primaryYAxis;

    // Chart xAxis
    if(chart.XAxis) {
      if(chart.XAxis.ValueType === 'Double') {
        if(chart.XAxis.CustomRange) {
          primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, interval: chart.XAxis.Interval, minimum: chart.XAxis.Minimum, maximum: chart.XAxis.Maximum, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
        else {
          primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
      }
      else if(chart.XAxis.ValueType === 'DateTime') {
        if(chart.XAxis.CustomRange) {
          primaryXAxis = { title: chart.XAxis.Title, minimum: chart.XAxis.Minimum, maximum: chart.XAxis.Maximum, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, intervalType: chart.XAxis.IntervalType, desiredInterval: chart.XAxis.Interval, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
        else {
          primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, intervalType: chart.XAxis.IntervalType, desiredInterval: chart.XAxis.Interval, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
      }
      else {
        primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
      }
    }

    // Chart yAxis
    if(chart.YAxis) {
      if(chart.YAxis.CustomRange) {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat, interval: chart.YAxis.Interval, minimum: chart.YAxis.Minimum, maximum: chart.YAxis.Maximum };
      }
      else {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat };
      }
    }

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, shared: true };

    return (
      <ChartComponent id={chart.Id} style={style} chartArea={chartArea} primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} legendSettings={legendSettings} tooltip={tooltip} ref={chart => this.chart = chart}>
        <SeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <SeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={serie.ChartType.Name} fill={serie.Color} marker={{ dataLabel: { name: 'Label', font: { fontWeight: serie.Marker.Label.Font.FontWeight, color: serie.Marker.Label.Font.Color }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible }, height: serie.Marker.Height, width: serie.Marker.Width, shape: serie.Marker.Shape, isFilled: serie.Marker.IsFilled, visible: serie.Marker.Visible }} xName='X' yName='Y' tooltipMappingName='XLabel' columnSpacing={0.1} width={serie.Width}></SeriesDirective>
          })}
        </SeriesCollectionDirective>
        <Inject services={[ Legend, ChartTooltip, Category, DataLabel, DateTime, Highlight, StripLine, AreaSeries, BarSeries, ColumnSeries, LineSeries, ParetoSeries, SplineAreaSeries, SplineSeries, WaterfallSeries, StackingAreaSeries, StackingBarSeries, StackingColumnSeries, StackingLineSeries ]} />
      </ChartComponent>
    );
  }

  templateChartBubble(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    let primaryXAxis, primaryYAxis;

    // Chart xAxis
    if(chart.XAxis) {
      if(chart.XAxis.ValueType === 'Double') {
        if(chart.XAxis.CustomRange) {
          primaryXAxis = { title: chart.XAxis.Title, labelFormat: chart.XAxis.LabelFormat, interval: chart.XAxis.Interval, minimum: chart.XAxis.Minimum, maximum: chart.XAxis.Maximum, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
        else {
          primaryXAxis = { title: chart.XAxis.Title, labelFormat: chart.XAxis.LabelFormat, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
      }
      else {
        primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
      }
    }

    // Chart yAxis
    if(chart.YAxis) {
      if(chart.YAxis.CustomRange) {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat, interval: chart.YAxis.Interval, minimum: chart.YAxis.Minimum, maximum: chart.YAxis.Maximum };
      }
      else {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat };
      }
    }

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, enableMarker: false };

    return (
      <ChartComponent id={chart.Id} style={style} chartArea={chartArea} primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} legendSettings={legendSettings} tooltip={tooltip} ref={chart => this.chart = chart}>
        <SeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <SeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={serie.ChartType.Name} marker={{ dataLabel: { name: 'Label', font: { fontWeight: serie.Marker.Label.Font.FontWeight }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible } }} xName='X' yName='Y' size='Size' pointColorMapping='Color' border={{ width: 2 }} minRadius={3} maxRadius={9} tooltipMappingName='Label'></SeriesDirective>
          })}
        </SeriesCollectionDirective>
        <Inject services={[ DataLabel, DateTime, ChartTooltip, BubbleSeries ]} />
      </ChartComponent>
    );
  }

  templateChartHeatmap(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    
    const style = { height: '100%', width: '100%' };
    const dataSourceSettings = { xDataMapping: 'X', yDataMapping: 'Y', valueMapping: 'Size', adaptorType: 'Cell', isJsonData: true };
    const cellSettings = { format: '{value}', showLabel: true, border: { radius: 4, width: 1, color: 'white' } };
    const paletteSettings = { palette: [{ color: '#DCD57E' }, { color: '#A6DC7E' }, { color: '#7EDCA2' }, { color: '#6EB5D0' }] };
    const xAxis = { labels: chart.XAxis.Labels, title: chart.XAxis.Title };
    const yAxis = { labels: chart.YAxis.Labels, title: chart.YAxis.Title };

    return (
      <HeatMapComponent id={chart.Id} style={style} dataSource={chart.Series[0].Points} dataSourceSettings={dataSourceSettings} cellSettings={cellSettings} paletteSettings={paletteSettings} xAxis={xAxis} yAxis={yAxis}>
        <Inject services={[ HeatmapTooltip, HeatmapLegend, Adaptor ]} />
      </HeatMapComponent>
    );
  }

  templateChartPolar(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    let primaryXAxis, primaryYAxis;

    // Chart xAxis
    if(chart.XAxis) {
      if(chart.XAxis.ValueType != 'Double' && chart.XAxis.ValueType != 'DateTime') {
        primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
      }
      else {
        return;
      }
    }

    // Chart yAxis
    if(chart.YAxis) {
      if(chart.YAxis.CustomRange) {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat, interval: chart.YAxis.Interval, minimum: chart.YAxis.Minimum, maximum: chart.YAxis.Maximum };
      }
      else {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat };
      }
    }

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, shared: true };

    return (
      <ChartComponent id={chart.Id} style={style} chartArea={chartArea} primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} legendSettings={legendSettings} tooltip={tooltip} ref={chart => this.chart = chart}>
        <SeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <SeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={serie.ChartType.Name} drawType={serie.DrawType.Name} fill={serie.Color} marker={{ dataLabel: { name: 'Label', font: { fontWeight: serie.Marker.Label.Font.fontWeight, color: serie.Marker.Label.Font.Color }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible }, height: serie.Marker.Height, width: serie.Marker.Width, shape: serie.Marker.Shape, isFilled: serie.Marker.IsFilled, visible: serie.Marker.Visible }} xName='X' yName='Y' tooltipMappingName='X' width={serie.Width}></SeriesDirective>
          })}
        </SeriesCollectionDirective>
        <Inject services={[ Legend, Category, DataLabel, DateTime, ChartTooltip, AreaSeries, LineSeries, PolarSeries, RadarSeries ]} />
      </ChartComponent>
    );
  }

  templateChartWidget(chart) {
    return (
      <div className="miniChartWidget scrollbar-miniblock">
        {chart.Series && chart.Series.map((serie, index) => {
          return <div key={index} className="chartWidgetSerie">
            {/* Serie Name */}
            {serie.Marker.Label.Visible && <div className="fs12 bold">{serie.Name}</div>}
            {/* Points */}
            {serie.Points && serie.Points.map((point, i) => {
              return <div key={i} className="minichartWidgetPoint">
                {/* Value */}
                <div className="fs40 bold" style={{ color: point.Color }}>{point.Label}</div>
                {/* Label */}
                {serie.Marker.Label.Visible && <div className="fs12">{point.X}</div>}
              </div>
            })}
          </div>
        })}
      </div>
    )
  }

  templatePivot() {
    const { language, itemId, itemType, blockType, currentView, columns, rows, dataSource, visibleGraph, visibleHeatmap } = this.state;
    let height, minimum;

    // Get Current View Chart Type
    let chartType = this.getChartType(currentView);

    // Get Current View Display Mode
    let displayMode = this.getDisplayMode(currentView);

    // Chart height
    if(blockType === 'Roadmap' || blockType === 'Decisions' || blockType === 'Issues' || blockType === 'Meetings' || blockType === 'Risks' || blockType === 'BudgetDetails' || blockType === 'Earnings' || blockType === 'Warnings') {
      height = (window.innerHeight - 140);
      minimum = 100;
    }
    else if(itemType === 'Resource') {
      height = (window.innerHeight - 270);
      minimum = 100;
    }
    else if(blockType === 'Burned' || blockType === 'Workload') {
      if(visibleGraph && visibleHeatmap) {
        height = (window.innerHeight - 140) / 2;
        minimum = 100;
      }
      else if(visibleGraph && !visibleHeatmap) {
        height = window.innerHeight - 140;
        minimum = 100;
      }
    }
    else {
      height = (window.innerHeight - 140) / 2;
      minimum = 100;
    }

    if(height > minimum) {
      height += 'px';
    }
    else {
      height = minimum + 'px';
    }

    // Display Options
    const displayOption = { view: 'Both', primary: displayMode };

    // Palette
    const palette = ['#0078A2','#0098B2','#00B7AF','#47D29B','#A1E981','#F9F871','#58ACD9','#CBF7FF','#EC9929','#6372B4','#896BB0','#AA62A3','#C35B8E','#8D7257','#374955','#9AAEBC','#009682','#EEE8A9','#3B867B'];

    // Chart Settings
    this.chartSettings = { chartSeries: { dataLabel: { visible: true, position: "Outside" }, type: chartType }, palettes: palette, enableSmartLabels: true };

    // Toolbar Options
    const toolbarOptions = ['Grid', 'Chart', 'FieldList', 'Export', 'Formatting', 'SubTotal', 'GrandTotal'];

    return (
      <div className="">
        {dataSource.length > 0 && 
          <PivotViewComponent id='PivotView' locale={Traduction.translate(language, 'locale')} height={height} dataSourceSettings={this.dataSourceSettings} chartSettings={this.chartSettings} displayOption={displayOption} created={this.created} dataBound={this.dataBound} enginePopulated={this.enginePopulated} onFieldDropped={this.onFieldDropped} exportComplete={this.exportComplete} toolbar={toolbarOptions} toolbarRender={this.toolbarRender} showToolbar={true} showFieldList={true} allowCalculatedField={true} allowConditionalFormatting={true} allowNumberFormatting={true} allowDeferLayoutUpdate={true} allowExcelExport={true} allowPdfExport={true} ref={chart=>this.chart=chart}>
            <Inject services={[ FieldList, Toolbar, PivotChart, CalculatedField, ConditionalFormatting, NumberFormatting, ExcelExport, PDFExport ]} /> 
          </PivotViewComponent>
        }
      </div>
    );
  }

  render() {
    const { language, itemId, itemType, blockType, currentView, chart, isLoading } = this.state;

    return (
      <div className="miniChart">
        {isLoading && <div className="miniChartComponent"></div>}
        {!isLoading && <div className="miniChartComponent">{this.displayComponent(chart)}</div>}
      </div>
    );
  }
}

export default MiniChart;