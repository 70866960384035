import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import MiniDoughnut from './MiniDoughnut';
import MiniTree from './MiniTree';
// import MiniTable from './MiniTable';
import Kanban from './Kanban';
import MiniPivot from './MiniPivot';
import MiniChart from './MiniChart.js';

class MiniBlockBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      guestLicence: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      filters: null,
      currentView: {},
      views: [],
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      emptyBlock: null
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const guestLicence = this.props.GuestLicence;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;

    // Build Data Structure
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [], chart = {};

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      // Chart
      if(blockContent.Chart) {
        chart = blockContent.Chart;
      }

      this.setState({ currentView, views, columns, rows, chart });
    }
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }
  
  render() {
    const { itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent, filters, currentView, views, columns, rows, chart, emptyBlock } = this.state;
    let visibleComponent;

    // Initialise Visible Component
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
      visibleComponent = this.convertStringtoBoolean(currentView.Parameters.find(param => param.Name === 'VisibleComponent').Value);
    }
    else {
      visibleComponent = true;
    }
    
    return (
      <div className="miniblock">
        <div className="miniblockContent">
          {/* Mini Components */}
          {columns && rows.length > 0 && <div className={(!visibleComponent ? "miniblockTable" : "miniblockComponent")}>
            {visibleComponent && currentView.ViewType === 0 && <MiniDoughnut ref={this.doughnut} CurrentView={currentView} Display={'Miniblock'} Columns={columns} Rows={rows}></MiniDoughnut>}
            {!visibleComponent && currentView.ViewType === 0 && <MiniTree ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniTree>}
            {/* {!visibleComponent && currentView.ViewType === 0 && <MiniTable ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniTable>} */}
            {currentView.ViewType === 2 && <Kanban ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} GuestLicence={guestLicence} CurrentView={currentView} Kanban={blockContent.Kanban} Columns={columns} Rows={rows} Axes={blockContent.AvailableAxes}></Kanban>}
            {currentView.ViewType === 3 && <MiniPivot ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPivot>}
          </div>}

          {/* Mini Chart */}
          {chart.Series && chart.Series.length > 0 && <div className="miniblockTable">
            {currentView.ViewType === 7 && <MiniChart ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} CurrentView={currentView} Chart={chart}></MiniChart>}
          </div>}
        </div>
      </div>
    )
  }
}

export default MiniBlockBudget;