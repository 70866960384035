import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import MiniTree from './MiniTree';
import MiniTable from './MiniTable';
import MiniPlanning from './MiniPlanning';
import Kanban from './Kanban';
import MiniPivot from './MiniPivot';
import MiniChart from './MiniChart.js';
import MiniMap from './MiniMap.js';
import EmptyBlockRoadmap from '../Images/EmptyBlockRoadmap.png';

class MiniBlockRoadMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      guestLicence: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      filters: null,
      currentView: {},
      views: [],
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      map: {},
      emptyBlock: null
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.getMonthName = this.getMonthName.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const guestLicence = this.props.GuestLicence;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;
    const filters = this.props.Filters;

    // Get Block Content Data
    this.buildBlockData(blockContent);
    
    this.setState({ language, itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent, filters });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [], chart = {}, map = {};
    let emptyBlock;

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      // Chart
      if(blockContent.Chart) {
        chart = blockContent.Chart;
      }

      // Map
      if(blockContent.Map) {
        map = blockContent.Map;
      }

      // Header
      // if(blockContent.Headers) {
      //   if(blockContent.Headers.find(element => element.FieldName === 'EmptyBlock')) {
      //     emptyBlock = blockContent.Headers.find(element => element.FieldName === 'EmptyBlock').FieldValue;
      //   }
      // }

      this.setState({ currentView, views, columns, rows, chart, map, emptyBlock });
    }
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0];
      const year = split[2];

      formattedDate = day + ' ' + this.getMonthName(month) + ' ' + year;
    }

    return formattedDate;
  }

  // Formatting Date to English format
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0];
      const year = split[2];

      formattedDate = day + ' ' + this.getMonthName(month) + ' ' + year;
    }

    return formattedDate;
  }

  getMonthName(month) {
    const language = this.state.language;

    switch(month) {
      case 1:
          return Traduction.translate(language, 'january');
      case 2:
          return Traduction.translate(language, 'february');
      case 3:
          return Traduction.translate(language, 'march');
      case 4:
          return Traduction.translate(language, 'april');
      case 5:
          return Traduction.translate(language, 'may');
      case 6:
          return Traduction.translate(language, 'june');
      case 7:
          return Traduction.translate(language, 'july');
      case 8:
          return Traduction.translate(language, 'august');
      case 9:
          return Traduction.translate(language, 'september');
      case 10:
          return Traduction.translate(language, 'october');
      case 11:
          return Traduction.translate(language, 'november');
      case 12:
          return Traduction.translate(language, 'december');
      default:
          break;
    }
  }
  
  render() {
    const { language, itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent, filters, currentView, views, columns, rows, chart, map, emptyBlock } = this.state;

    return (
      <div className="miniblockRoadmap">
        <div className="miniblockContent">
          {/* Mini Components */}
          {columns && rows.length > 0 && <div className="miniblockTable">
          {/* {columns && rows.length > 0 && <div className="miniblockTable scrollbar-miniblock"> */}
            {currentView.ViewType === 0 && currentView.DefaultLevel === 0 && <MiniTree ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniTree>}
            {currentView.ViewType === 0 && currentView.DefaultLevel !== 0 && <MiniTable ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniTable>}
            {currentView.ViewType === 1 && <MiniPlanning ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPlanning>}
            {currentView.ViewType === 2 && <Kanban ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} GuestLicence={guestLicence} CurrentView={currentView} Kanban={blockContent.Kanban} Columns={columns} Rows={rows} Axes={blockContent.AvailableAxes}></Kanban>}
            {currentView.ViewType === 3 && <MiniPivot ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPivot>}
          </div>}

          {/* Mini Chart */}
          {chart.Series && chart.Series.length > 0 && <div className="miniblockTable">
            {currentView.ViewType === 7 && <MiniChart ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} CurrentView={currentView} Chart={chart}></MiniChart>}
          </div>}

          {/* Mini Map */}
          {map.Points && map.Points.length > 0 && <div className="miniblockTable">
            {currentView.ViewType === 8 && <MiniMap ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} CurrentView={currentView} Map={map}></MiniMap>}
          </div>}
          
          {/* Empty Block */}
          {/* {Array.isArray(rows) && rows.length === 0 && filters == 0 && 
            <div className="miniblockEmpty scrollbar-miniblock">
              <div className=""><img className="iconsEmptyBlock" src={EmptyBlockRoadmap} alt="EmptyBlock Roadmap"/></div>
              <div className=""><div className="miniblockEmptyText" dangerouslySetInnerHTML={{ __html: emptyBlock }}></div></div>
            </div>
          } */}
        </div>
      </div>
    )
  }
}

export default MiniBlockRoadMap;