import React, {Component} from 'react';
import { Badge, ProgressBar, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { L10n } from '@syncfusion/ej2-base';
import { getObject } from '@syncfusion/ej2-grids';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, ColumnMenu } from '@syncfusion/ej2-react-treegrid';
import { Inject, Edit, ContextMenu, Filter, Sort, Page, Reorder, Resize, ExcelExport } from '@syncfusion/ej2-react-treegrid';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import QRCode from "react-qr-code";
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import Timeline from './Timeline';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class MiniTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      display: null,
      editable: null,
      pagging: false,
      currentView: {},
      dictParameters: {},
      columns: [],
      dictColumns: {},
      dataSource: [],
      refreshCount: 0,
      refreshInProgress: false,
      error: {}
    };

    // Tree Structure
    this.dataStructure = this.dataStructure.bind(this);
    this.getCurrentViewParameters = this.getCurrentViewParameters.bind(this);
    this.getColumnParameters = this.getColumnParameters.bind(this);

    // Fonctions
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
    this.getColumnName = this.getColumnName.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    // this.getColumnUnit = this.getColumnUnit.bind(this);
    // this.getColumnConditionalFormattings = this.getColumnConditionalFormattings.bind(this);
    this.getCellConditionalFormatting = this.getCellConditionalFormatting.bind(this);
    this.isConditionalFormattingRespected = this.isConditionalFormattingRespected.bind(this);
    this.getTreeColumnIndex = this.getTreeColumnIndex.bind(this);

    // Syncfusion Events
    this.rowDataBound = this.rowDataBound.bind(this);
    this.actionBegin = this.actionBegin.bind(this);

    // Actions
    this.refreshComponent = this.refreshComponent.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.refreshDatasource = this.refreshDatasource.bind(this);
    this.createColumn = this.createColumn.bind(this);
    this.collapsing = this.collapsing.bind(this);
    this.expanding = this.expanding.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.excelQueryCellInfo = this.excelQueryCellInfo.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);

    // Template Functions
    this.templateColumnAction = this.templateColumnAction.bind(this);
    this.templateColumnActive = this.templateColumnActive.bind(this);
    this.templateColumnAdmin = this.templateColumnAdmin.bind(this);
    this.templateColumnBudgetCode = this.templateColumnBudgetCode.bind(this);
    this.templateColumnBusinessLine = this.templateColumnBusinessLine.bind(this);
    this.templateColumnDataFreshness = this.templateColumnDataFreshness.bind(this);
    this.templateColumnEmail = this.templateColumnEmail.bind(this);
    this.templateColumnEntity = this.templateColumnEntity.bind(this);
    this.templateColumnExternal = this.templateColumnExternal.bind(this);
    this.templateColumnHighlighted = this.templateColumnHighlighted.bind(this);
    this.templateColumnImpact = this.templateColumnImpact.bind(this);
    this.templateColumnItemType = this.templateColumnItemType.bind(this);
    this.templateColumnLag = this.templateColumnLag.bind(this);
    this.templateColumnLicenceType = this.templateColumnLicenceType.bind(this);
    this.templateColumnMeteo = this.templateColumnMeteo.bind(this);
    this.templateColumnMeteoFreshness = this.templateColumnMeteoFreshness.bind(this);
    this.templateColumnMonthBurned = this.templateColumnMonthBurned.bind(this);
    this.templateColumnName = this.templateColumnName.bind(this);
    this.templateColumnPriority = this.templateColumnPriority.bind(this);
    this.templateColumnProbability = this.templateColumnProbability.bind(this);
    this.templateColumnProgress = this.templateColumnProgress.bind(this);
    this.templateColumnProject = this.templateColumnProject.bind(this);
    this.templateColumnResourceName = this.templateColumnResourceName.bind(this);
    this.templateColumnRowType = this.templateColumnRowType.bind(this);
    this.templateColumnSprint = this.templateColumnSprint.bind(this);
    this.templateColumnStatus = this.templateColumnStatus.bind(this);
    this.templateColumnTask = this.templateColumnTask.bind(this);
    this.templateColumnTimeline = this.templateColumnTimeline.bind(this);
    this.templateColumnTrend = this.templateColumnTrend.bind(this);
    this.templateColumnValidated = this.templateColumnValidated.bind(this);
    this.templateColumnWarning = this.templateColumnWarning.bind(this);
    this.templateColumnWorkpackage = this.templateColumnWorkpackage.bind(this);
    this.templateColumnYear = this.templateColumnYear.bind(this);

    this.templateTypeAxe = this.templateTypeAxe.bind(this);
    this.templateTypeAxisTable = this.templateTypeAxisTable.bind(this);
    this.templateTypeBoolean = this.templateTypeBoolean.bind(this);
    this.templateTypeDate = this.templateTypeDate.bind(this);
    this.templateTypeDouble = this.templateTypeDouble.bind(this);
    this.templateTypeFiles = this.templateTypeFiles.bind(this);
    this.templateTypeHTML = this.templateTypeHTML.bind(this);
    this.templateTypeLink = this.templateTypeLink.bind(this);
    this.templateTypeLocation = this.templateTypeLocation.bind(this);
    this.templateTypePercentage = this.templateTypePercentage.bind(this);
    this.templateTypeProgress = this.templateTypeProgress.bind(this);
    this.templateTypeQRCode = this.templateTypeQRCode.bind(this);
    this.templateTypeRating = this.templateTypeRating.bind(this);
    this.templateTypeResource = this.templateTypeResource.bind(this);
    this.templateTypeResourceTable = this.templateTypeResourceTable.bind(this);
    this.templateTypeText = this.templateTypeText.bind(this);
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const display = this.props.Display;
    const editable = this.props.Editable;
    const pagging = this.props.Pagging;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Planning Datasource
    const dataSource = this.dataStructure(rows);

    this.setState({ authId, language, formatDate, itemId, itemType, itemTitle, blockType, display, editable, pagging, currentView, dictParameters, dictColumns, columns, dataSource, refreshCount: 1 });
  }

  componentDidUpdate(prevProps) {
    
  }

  // shouldComponentUpdate(prevProps, prevState) {
  //   if(this.state.refreshCount !== prevState.refreshCount) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  // Build Datasource
  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Meteo/Trend
          else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Hashtags
          else if((item.FieldType === 'ObjectTable' || item.FieldType === 'Files' || item.FieldType === 'ResourceTable') && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          else {
            acc[item.ColumnName] = item.Value;
          }

          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  // Build Current View Parameters
  getCurrentViewParameters(currentView) {
    let dictParameters = {};

    // Default values
    dictParameters['Columns'] = [];
    dictParameters['ColumnsWidth'] = [];

    for(let i=0; i < currentView.Parameters.length; i++) {
      // Columns
      if(currentView.Parameters[i].Name === 'Columns') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value.split(',');
        }
      }
      // Columns Width
      else if(currentView.Parameters[i].Name === 'ColumnsWidth') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = JSON.parse(currentView.Parameters[i].Value);
        }
      }
      else {
        dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value;
      }
    }

    return dictParameters;
  }

  // Build Current View Columns
  getColumnParameters(columnHeaders) {
    let dictColumns = {};

    for(let i=0; i < columnHeaders.length; i++) {
      if(columnHeaders[i]) {
        dictColumns[columnHeaders[i].FieldName] = columnHeaders[i];
      }
    }

    return dictColumns;
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format for Database
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  getColumnName(props) {
    const dictParameters = this.state.dictParameters;
    let columnName = '';

    // Get Column Name
    if(!props || !props.column) {
      columnName = dictParameters['Columns'][0];
    }
    else {
      columnName = props.column.field;
    }

    return columnName.replace(".Label", "");
  }

  getColumnType(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.FieldType;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).FieldType;
    // }
    // else {
    //   return null;
    // }
  }

  getColumnUnit(columnName) {
    // let columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.Unit;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).Unit;
    // }
    // else {
    //   return null;
    // }
  }

  getColumnConditionalFormattings(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.ConditionalFormattings;
    }
    else {
      return [];
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).ConditionalFormattings;
    // }
    // else {
    //   return [];
    // }
  }

  getCellConditionalFormatting(conditionalFormattings, value) {
    if(conditionalFormattings) {
      for(let i = 0; i < conditionalFormattings.length; i++) {
        if(this.isConditionalFormattingRespected(conditionalFormattings[i], value)) {
          return conditionalFormattings[i];
        }
      }
    }
    else {
      return null;
    }
  }

  isConditionalFormattingRespected(conditionalFormatting, value) {
    if(conditionalFormatting) {
      switch(conditionalFormatting.Conditions) {
        case 'equal':
            if(conditionalFormatting.Value1 == value) {
              return true;
            }
            else {
              return false;
            }
        case 'notequal':
            if(conditionalFormatting.Value1 != value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthan':
            if(conditionalFormatting.Value1 < value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthanorequal':
            if(conditionalFormatting.Value1 <= value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthan':
            if(conditionalFormatting.Value1 > value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthanorequal':
            if(conditionalFormatting.Value1 >= value) {
              return true;
            }
            else {
              return false;
            }
        case 'between':
            if(conditionalFormatting.Value1 <= value && conditionalFormatting.Value2 >= value) {
              return true;
            }
            else {
              return false;
            }
        default:
            return false;
      }
    }
    else {
      return false;
    }
  }

  getTreeColumnIndex(columns) {
    for(let i = 0; i < columns.length; i++) {
      if(columns[i] === 'Name') {
        return i;
      }
    }

    return 0;
  }

  rowDataBound(args) {
    const rowItemType = getObject('Item_Type', args.data);

    // Define styles for Objects rows
    if(rowItemType === 'Business_Line') {
      args.rowHeight = 30;
      // args.row.style.fontSize = '12px';
      // args.row.style.fontWeight = 'bold';
    }
    else if(rowItemType === 'Project') {
      args.rowHeight = 30;
      // args.row.style.fontSize = '12px';
      // args.row.style.fontWeight = '500';
    }
    else if(rowItemType === 'Workpackage') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '500';

      // Skip border top for the first element
      if(args.data.index !== 0) {
        args.row.style.borderTop = '1px solid #777777';
      }
    }
    else if(rowItemType === 'Action') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '500';

      // Skip border top for the first element
      if(args.data.index !== 0) {
        args.row.style.borderTop = '1px solid #D4D4DA';
      }
    }
    else if(rowItemType === 'Task') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '100';
    }
    else if(rowItemType === 'Definition') {
      if(args.data.Parent_ID) {
        args.rowHeight = 30;
        args.row.style.borderTop = '1px solid #D4D4DA';
      }
      else {
        args.rowHeight = 30;
      }
    }
    else if(rowItemType === 'AgileBoard') {
      args.rowHeight = 30;
    }
    else if(rowItemType === 'Sprint') {
      args.rowHeight = 30;
      args.row.style.borderBottom = '1px solid #777777';
    }
    // Specific display for Chart Mini Tree
    else {
      // Skip border top for the first element
      if(args.data.index !== 0) {
        args.row.style.borderTop = '1px solid #D4D4DA';
      }
    }
  }

  actionBegin(args) {
    const { refreshInProgress } = this.state;

    // Refresh
    if(args.requestType === 'refresh') {
      if(refreshInProgress) {
        args.cancel = true;
      }
      else {
        args.cancel = false;
      }
    }
    // Search
    // else if(args.requestType === 'searching') {
    //   if(!args.searchString) {
    //     args.cancel = true;
    //   }
    // }
  }

  // Refresh Table Component
  refreshComponent(blockType, currentView, columns, rows) {
    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Table Datasource
    const dataSource = this.dataStructure(rows);

    this.setState({ currentView, dictParameters, dictColumns, dataSource, refreshInProgress: true });

    this.setState({ refreshInProgress: false });

    // Refresh Table Datasource
    this.refreshDatasource(dataSource);

    // Refresh Table Columns
    this.refreshColumns(blockType, currentView, dictParameters, dictColumns);

    this.treegrid.refreshColumns();
  }

  refreshColumns(blockType, currentView, dictParameters, dictColumns) {
    let treegridColumns = [];

    // Auto Generated Columns
    if(this.treegrid) {
      // Clean Treegrid columns
      // this.treegrid.columns = [];

      // Loop through the columns to build and add them to the Treegrid
      for(let i=0; i < dictParameters['Columns'].length; i++) {
        let columnName = dictParameters['Columns'][i];

        if(dictColumns[columnName]) {
          // Create Column object
          const colObj = this.createColumn(dictColumns[columnName], dictParameters['ColumnsWidth'], true);

          // Add the Column in the Treegrid
          treegridColumns.push(colObj);
          // this.treegrid.columns.push(colObj);
        }
      }

      // Add Column Item_ID
      if(!dictParameters['Columns'].includes('Item_ID')) {
        let columnHeader = dictColumns['Item_ID'];

        // Define Column object for Treegrid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerText: columnHeader.Label,
          visible: false,
          allowEditing: false,
          isPrimaryKey: true
        };

        treegridColumns.push(colObj);
        // this.treegrid.columns.push(colObj);
      }
      // Add Column Item_Type
      if(!dictParameters['Columns'].includes('Item_Type')) {
        let columnHeader = dictColumns['Item_Type'];

        // Define Column object for Treegrid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerText: columnHeader.Label,
          visible: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        treegridColumns.push(colObj);
        // this.treegrid.columns.push(colObj);
      }

      // Update Tree Columns
      this.treegrid.columns = treegridColumns;

      // Update Tree Column Index
      this.treegrid.treeColumnIndex = this.getTreeColumnIndex(dictParameters['Columns']);
    }
  }

  refreshDatasource(dataSource) {
    if(this.treegrid) {
      this.treegrid.dataSource = dataSource;
    }
  }

  createColumn(columnHeader, columnsWidth, visible) {
    const { itemType, blockType } = this.state;
    let label, type, field, primaryKey, textAlign, template, width;

    // Label
    if(blockType !== 'Warnings' && columnHeader.FieldName === 'WarningMessage') {
      label = 'W';
    }
    else {
      label = columnHeader.Label;
    }

    // Type
    if(columnHeader.FieldType === 'String'  || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      type = 'string';
    }
    else if(columnHeader.FieldType === 'Double' || columnHeader.FieldType === 'Percentage') {
      type = 'number';
    }
    else if(columnHeader.FieldType === 'Date') {
      type = 'date';
    }
    else {
      type = null;
    }

    // Field (Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component)
    if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'AxisTable' || columnHeader.FieldType === 'Resource' || columnHeader.FieldType === 'ResourceTable' || columnHeader.FieldType === 'Location' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend' || columnHeader.FieldName === 'Business_Line' || columnHeader.FieldName === 'Project' || columnHeader.FieldName === 'Workpackage' || columnHeader.FieldName === 'Action' || columnHeader.FieldName === 'Task' || columnHeader.FieldName === 'Entity') {
      field = columnHeader.FieldName;
      // field = columnHeader.FieldName + ".Label";
    }
    else {
      field = columnHeader.FieldName;
    }

    // Primary Key
    if(columnHeader.FieldName === 'Item_ID') {
      primaryKey = true;
    }
    else {
      primaryKey = false;
    }

    // Text Align
    if(columnHeader.FieldType === 'Date' || columnHeader.FieldName === 'Active' || columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config' || columnHeader.FieldName === 'Administrator' || columnHeader.FieldName === 'Reporting' || columnHeader.FieldName === 'Previous_Lag' || columnHeader.FieldName === 'Next_Lag') {
      textAlign = 'center';
    }
    else if(columnHeader.FieldType === 'Double' && (columnHeader.FieldName !== 'Meteo' && columnHeader.FieldName !== 'Trend')) {
      textAlign = 'right';
    }

    // Template functions
    // By FieldName
    if(columnHeader.FieldName === 'Action') {
      template = this.templateColumnAction;
    }
    else if(columnHeader.FieldName === 'Active') {
      template = this.templateColumnActive;
    }
    else if(columnHeader.FieldName === 'Admin') {
      template = this.templateColumnAdmin;
    }
    else if(columnHeader.FieldName === 'BudgetCode') {
      template = this.templateColumnBudgetCode;
    }
    else if(columnHeader.FieldName === 'Business_Line') {
      template = this.templateColumnBusinessLine;
    }
    else if(columnHeader.FieldName === 'Data_Freshness') {
      template = this.templateColumnDataFreshness;
    }
    else if(columnHeader.FieldName === 'Email') {
      template = this.templateColumnEmail;
    }
    else if(columnHeader.FieldName === 'Entity') {
      template = this.templateColumnEntity;
    }
    else if(columnHeader.FieldName === 'External') {
      template = this.templateColumnExternal;
    }
    else if(columnHeader.FieldName === 'Highlighted') {
      template = this.templateColumnHighlighted;
    }
    else if(columnHeader.FieldName === 'Impact') {
      template = this.templateColumnImpact;
    }
    else if(columnHeader.FieldName === 'Item_Type') {
      template = this.templateColumnItemType;
    }
    else if (columnHeader.FieldName === 'Next_Lag' || columnHeader.FieldName === 'Previous_Lag') {
      template = this.templateColumnLag;
    }
    else if(columnHeader.FieldName === 'Licence_Type') {
      template = this.templateColumnLicenceType;
    }
    else if(columnHeader.FieldName === 'Meteo') {
      template = this.templateColumnMeteo;
    }
    else if(columnHeader.FieldName === 'Meteo_Freshness') {
      template = this.templateColumnMeteoFreshness;
    }
    else if(columnHeader.FieldName === 'Month_Burned') {
      template = this.templateColumnMonthBurned;
    }
    else if(columnHeader.FieldName === 'Name') {
      if(itemType === 'Entity' && blockType === 'Resources') {
        template = this.templateColumnResourceName;
      }
      else {
        template = this.templateColumnName;
      }
    }
    else if(columnHeader.FieldName === 'Priority') {
      template = this.templateColumnPriority;
    }
    else if(columnHeader.FieldName === 'Probability') {
      template = this.templateColumnProbability;
    }
    else if(columnHeader.FieldName === 'Progress') {
      template = this.templateColumnProgress;
    }
    else if(columnHeader.FieldName === 'Project') {
      template = this.templateColumnProject;
    }
    else if(columnHeader.FieldName === 'RowType') {
      template = this.templateColumnRowType;
    }
    else if(columnHeader.FieldName === 'Status') {
      template = this.templateColumnStatus;
    }
    else if(columnHeader.FieldName === 'Task') {
      template = this.templateColumnTask;
    }
    else if(columnHeader.FieldName === 'Timeline') {
      template = this.templateColumnTimeline;
    }
    else if(columnHeader.FieldName === 'Trend') {
      template = this.templateColumnTrend;
    }
    else if(columnHeader.FieldName === 'Validated') {
      template = this.templateColumnValidated;
    }
    else if(columnHeader.FieldName === 'WarningMessage') {
      template = this.templateColumnWarning;
    }
    else if(columnHeader.FieldName === 'Workpackage') {
      template = this.templateColumnWorkpackage;
    }
    else if(columnHeader.FieldName === 'Year') {
      template = this.templateColumnYear;
    }
    // By Type
    else if(columnHeader.FieldType === 'Axe') {
      template = this.templateTypeAxe;
    }
    else if(columnHeader.FieldType === 'AxisTable') {
      template = this.templateTypeAxisTable;
    }
    else if(columnHeader.FieldType === 'Boolean') {
      template = this.templateTypeBoolean;
    }
    else if(columnHeader.FieldType === 'Date') {
      template = this.templateTypeDate;
    }
    else if(columnHeader.FieldType === 'Double') {
      template = this.templateTypeDouble;
    }
    else if(columnHeader.FieldType === 'Files') {
      template = this.templateTypeFiles;
    }
    else if(columnHeader.FieldType === 'HTML') {
      template = this.templateTypeHTML;
    }
    else if(columnHeader.FieldType === 'Link') {
      template = this.templateTypeLink;
    }
    else if(columnHeader.FieldType === 'Location') {
      template = this.templateTypeLocation;
    }
    else if(columnHeader.FieldType === 'Percentage') {
      template = this.templateTypePercentage;
    }
    else if(columnHeader.FieldType === 'Progress') {
      template = this.templateTypeProgress;
    }
    else if(columnHeader.FieldType === 'QRCode') {
      template = this.templateTypeQRCode;
    }
    else if(columnHeader.FieldType === 'Rating') {
      template = this.templateTypeRating;
    }
    else if(columnHeader.FieldType === 'Resource') {
      template = this.templateTypeResource;
    }
    else if(columnHeader.FieldType === 'ResourceTable') {
      template = this.templateTypeResourceTable;
    }
    else {
      template = this.templateTypeText;
    }

    // Width
    if(columnsWidth.find(column => column.Name === columnHeader.FieldName)) {
      width = columnsWidth.find(column => column.Name === columnHeader.FieldName).Width;
    }
    else {
      if(columnHeader.FieldName === 'Item_ID') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Item_Type') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Name') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Status') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Priority') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Probability') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Impact') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Meteo') {
        width = 70;
      }
      else if(columnHeader.FieldName === 'Trend') {
        width = 80;
      }
      else if(columnHeader.FieldName === 'Progress') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Workload') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Burned_Workload') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'StartDate') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'EndDate') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Timeline') {
        width = 180;
      }
      else if(columnHeader.FieldName === 'Resource') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'Task_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'WarningMessage') {
        width = 50;
      }
      else if(columnHeader.FieldName === 'Description') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Comment') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Corrective_Action') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Decision_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Meeting_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Resource_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Owner') {
        width = 140;
      }
      else if(columnHeader.FieldName === 'Project') {
        width = 220;
      }
      else if(columnHeader.FieldName === 'Message') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Time') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Active') {
        width = 80;
      }
      else if(columnHeader.FieldName === 'Email' ) {
        width = 200;
      }
      else if(columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Reporting') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config' || columnHeader.FieldName === 'Administrator') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Firm') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Login') {
        width = 180;
      }
      else if(columnHeader.FieldName === 'Licence_EndDate') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'First_Name' || columnHeader.FieldName === 'Last_Name') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Licence_Type') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Highlighted' || columnHeader.FieldName === 'External' || columnHeader.FieldName === 'Validated') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Data_Freshness') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Meteo_Freshness') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Import_Row') {
        width = 60;
      }
      else if(columnHeader.FieldName === 'Import_Event' || columnHeader.FieldName === 'Import_Status') {
        width = 100;
      }
    }

    // Define Column object for Grid
    var colObj = {
      allowEditing: false,
      field: field,
      headerText: label,
      headerTextAlign: 'left',
      isPrimaryKey: primaryKey,
      lockColumn: false,
      textAlign: textAlign,
      type: type,
      template: template,
      visible: true,
      width: width
    };

    return colObj;
  }

  collapsing(args) {
    // this.treegrid.refreshColumns();
  }

  expanding(args) {
    // this.treegrid.refreshColumns();
  }

  resizeStop(args) {
    // const currentView = this.state.currentView;
    // let columnsWidth = [];

    // // Loop through the Treegrid columns to build CurrentView Columns Width
    // for(let i=0; i < this.treegrid.columns.length; i++) {
    //   columnsWidth.push({ Name: this.treegrid.columns[i].field, Width: this.treegrid.columns[i].width });
    // }

    // // Update Current View Columns Width
    // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
    //   currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    // }
    // // Create Current View Columns Width
    // else {
    //   currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    // }

    // // Call the event from the Parent component through the props with view value
    // this.props.onViewChange(currentView);
  }

  // Format Cells value for Export
  excelQueryCellInfo(args) {
    const columnName = args.column.field.replace(".Label", "");
    const columnType = this.getColumnType(columnName);
    const value = args.data[columnName];

    // Dates
    if(value && columnType === 'Date') {
      args.value = new Date(value).toLocaleDateString();
    }
    // Percentage
    else if(columnType === 'Percentage') {
      if(args.value) {
        args.value = value.toFixed(0) + "%";
      }
      else {
        args.value = "0%";
      }
    }
    // QRCode
    else if(args.column.headerText === 'QR Code') {
      args.value = "";
    }
    // Objects
    else if(value && (columnType === 'Axe' || columnType === 'Resource' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Timeline' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity')) {
      args.value = value.Label;
    }
    // Table Objects
    else if(value && (columnType === 'AxisTable' || columnType === 'ResourceTable' || columnType === 'Files' || columnName === 'Rights')) {
      let string = "";

      value.forEach((item, index) => {
        if(index < value.length - 1) {
          string = string.concat(item.Label, ", ");
        }
        else {
          string = string.concat(item.Label);
        }
      });

      args.value = string;
    }
  }

  exportCSV() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".csv";

    if(this.treegrid) {
      // Define Export File Name
      let csvExportProperties = { fileName: fileName };

      this.treegrid.csvExport(csvExportProperties);
    }
  }

  exportXLSX() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".xlsx";

    if(this.treegrid) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };
      
      this.treegrid.excelExport(excelExportProperties);
    }
  }

  searchItem(search) {
    if(this.treegrid) {
      this.treegrid.search(search);
    }
  }

  // ----- ----- Format Functions ----- -----
  // ----- ----- Columns
  templateColumnAction(props) {
    const action = props.Action;

    if(action) {
      if(action.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{action.Label}</div>;
      }
      else if(action.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/Action/${action.Id}/Home`}><span className="iconAction verysmallIcons mr5"></span></a>
          <span className="">{action.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnActive(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;
    
    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconActiveTrue iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconActiveFalse iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnAdmin(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconShieldGreen iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconShieldGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnBudgetCode(props) {
    const budgetCode = props.BudgetCode;

    if(budgetCode) {
      if(budgetCode.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{budgetCode.Label}</div>;
      }
      else if(budgetCode.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/BudgetCode/${budgetCode.Id}/Home`}><span className="iconBudgetCode verysmallIcons mr5"></span></a>
          <span className="">{budgetCode.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnBusinessLine(props) {
    const businessLine = props.Business_Line;

    if(businessLine) {
      if(businessLine.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{businessLine.Label}</div>;
      }
      else if(businessLine.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/Business_Line/${businessLine.Id}/Home`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
          <span className="">{businessLine.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnDataFreshness(props) {
    const dataFreshness = props.Data_Freshness;

    if(dataFreshness === "1") {
      return <div className="iconFullCircleGreen iconsMiniblock"></div>;
    } 
    else if(dataFreshness === "2") {
      return <div className="iconFullCircleOrange iconsMiniblock"></div>;
    } 
    else if(dataFreshness === "3") {
      return <div className="iconFullCircleRed iconsMiniblock"></div>;
    }
    else if(dataFreshness === "4") {
      return <div className="iconCircleGrey iconsMiniblock"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnEmail(props) {
    const email = props.Email;

    if(email) {
      return <a href={"mailto:" + email} className="emailTable">{email}</a>;
      // return <div onClick={(e) => {window.location = "mailto:" + email; e.preventDefault();}} className="emailTable">{email}</div>
    }
    else {
      return null;
    }
  }

  templateColumnEntity(props) {
    const entity = props.Entity;

    if(entity) {
      if(entity.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{entity.Label}</div>;
      }
      else if(entity.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/Entity/${entity.Id}/Home`}><span className="iconEntity verysmallIcons mr5"></span></a>
          <span className="">{entity.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnExternal(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconExternal iconsRectangleMiniTable align-items-center"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconInternal iconsRectangleMiniTable align-items-center"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnHighlighted(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconHighlighted iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconNotHighlighted iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnImpact(props) {
    const impact = props.Impact;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact) {
      switch(impact.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{impact.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnItemType(props) {
    const itemType = props.Item_Type;

    switch(itemType) {
      case 'Business_Line':
          return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{itemType}</div>;
      case 'Project':
          return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{itemType}</div>;
      case 'Workpackage':
          return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{itemType}</div>;
      case 'Action':
          return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{itemType}</div>;
      case 'Task':
          return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{itemType}</div>;
      default:
          return <div className="">{itemType}</div>;
    }
  }

  templateColumnLag(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let backColor, foreColor = '#FFFFFF';

    if(value > 0) {
      backColor = "#00C77A";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" + " + value+ " " }</div>;
    }
    else if(value < 0) {
      backColor = "#E21313";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" - " + (-value) + " "}</div>;
    }
    else if(value === 0) {
      backColor = "#FFFFFF";
      foreColor = "#9D9D9F";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: foreColor }}>{" " + value + " "}</div>;
    }
    else {
      return null;
    }
  }

  templateColumnLicenceType(props) {
    const licenceType = props.Licence_Type;
    
    switch(licenceType) {
      case 'Full':
          return <div className="minitableAxe brd-radius bg-green white">{licenceType}</div>;
      case 'Standard':
          return <div className="minitableAxe bordered green">{licenceType}</div>;
      case 'No Licence':
      case 'Duplicated license':
      case 'Invalid License':
          return <div className="minitableAxe bordered grey">{licenceType}</div>;
      default:
          return null;
    }
  }

  templateColumnMeteo(props) {
    const dictParameters = this.state.dictParameters;
    const meteo = props.Meteo;

    // Meteo (0: None, 1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
    if(meteo) {
      if(dictParameters['QualitativeDisplay'] && dictParameters['QualitativeDisplay'] === 'Traffic') {
        switch(meteo.Id) {
          case 1:
              return <div className="iconFullCircleGreen iconsTable"></div>;
          case 2:
              return <div className="iconFullCircleOrange iconsTable"></div>;
          case 3:
              return <div className="iconFullCircleRed iconsTable"></div>;
          case 4:
              return <div className="iconFullCircleDarkRed iconsTable"></div>;
          // case 5:
          //     return <div className=""><img className="iconsTable" src={IconMoon} alt="Moon"/></div>;
          default:
              return null;
        }
      }
      else {
        switch(meteo.Id) {
          case 1:
              return <div className=""><img className="iconsTable" src={IconSun} alt="Sun"/></div>;
          case 2:
              return <div className=""><img className="iconsTable" src={IconCloud} alt="Cloud"/></div>;
          case 3:
              return <div className=""><img className="iconsTable" src={IconRain} alt="Rain"/></div>;
          case 4:
              return <div className=""><img className="iconsTable" src={IconThunder} alt="Thunder"/></div>;
          // case 5:
          //     return <div className=""><img className="iconsTable" src={IconMoon} alt="Moon"/></div>;
          default:
              return null;
        }
      }
    }
    else {
      return null;
    }
  }

  templateColumnMeteoFreshness(props) {
    const meteoFreshness = props.Meteo_Freshness;

    if(meteoFreshness === "1") {
      return <div className="iconFullCircleGreen iconsMiniblock"></div>;
    } 
    else if(meteoFreshness === "2") {
      return <div className="iconFullCircleOrange iconsMiniblock"></div>;
    } 
    else if(meteoFreshness === "3") {
      return <div className="iconFullCircleRed iconsMiniblock"></div>;
    }
    else if(meteoFreshness === "4") {
      return <div className="iconCircleGrey iconsMiniblock"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnMonthBurned(props) {
    const monthBurned = props.Month_Burned;

    if(monthBurned == 0) {
      return <div className="monthBurned brd-dashed-timetracking blue bold">{monthBurned}</div>;
    }
    else {
      return <div className="monthBurned brd-timetracking blue bold">{monthBurned}</div>;
    }
  }

  templateColumnName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;

    switch(itemType) {
      case 'AgileBoard':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconGrey iconAgileBoard verysmallIcons" alt="AgileBoard"></span></a>{name}</div>);
      case 'Sprint':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconGrey iconSprint verysmallIcons" alt="Sprint"></span></a>{name}</div>);
      case 'Business_Line':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span></a>{name}</div>);
      case 'Project':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span></a>{name}</div>);
      case 'Workpackage':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span></a>{name}</div>);
      case 'Action':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span></a>{name}</div>);
      case 'Task':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Details`}><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span></a>{name}</div>);
      default:
          return <span className="">{name}</span>;
    }
  }

  templateColumnPriority(props) {
    const priority = props.Priority;

    // Priority (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(priority) {
      switch(priority.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{priority.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProbability(props) {
    const probability = props.Probability;

    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(probability) {
      switch(probability.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{probability.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProgress(props) {
    let progress = props.Progress;
    // progress = (props.Progress.replace(',', '.') * 100).toFixed(0);
    
    if(progress) {
      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="inline-flex align-items-center">
          <span className="width45">{`${progress} %`}</span>
          <span className="width80"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateColumnProject(props) {
    const project = props.Project;

    if(project) {
      if(project.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{project.Label}</div>;
      }
      else if(project.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/Project/${project.Id}/Home`}><span className="iconProject verysmallIcons mr5"></span></a>
          <span className="">{project.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnResourceName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;

    if(name === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name) {
      let firstname, lastname;
      let split = name.split(' ');

      if(split.length === 1) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[1].substring(0,1);
      }
      else {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="align-baseline">
        <a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnRowType(props) {
    const rowType = props.RowType;

    if(rowType === 'Holiday') {
      return <div className="turquoise bold"><span className="iconHolidays tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'Forecast') {
      return <div className="blue"><span className="iconHourglass tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'TimeTracking') {
      return <div className="blue bold"><span className="iconTimeTrackingBlue tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else {
      return <div className="">{rowType}</div>;
    }
  }

  templateColumnSprint(props) {
    const blockType = this.state.blockType;
    const sprint = props.Sprint;

    let backColor, foreColor, borderColor;

    // Back, Fore & Border Color
    if(sprint) {
      if(!sprint.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = sprint.BackColor;
      }
      if(!sprint.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((sprint.ForeColor === '#FFFFFF' || sprint.ForeColor === '#ffffff')) {
          borderColor = sprint.BackColor;
        }
        else {
          borderColor = sprint.ForeColor;
        }

        foreColor = sprint.ForeColor;
      }
    }

    if(sprint) {
      if(sprint.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{sprint.Label}</div>;
      }
      else if(sprint.Label) {
        return <div className="minitableAxe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>
          {(backColor === '#FFFFFF' || backColor === '#ffffff') && <a target="_self" href={`/Card/Sprint/${sprint.Id}/${blockType}`}><span className="iconSprint verysmallIcons mr5"></span></a>}
          {(backColor !== '#FFFFFF' && backColor !== '#ffffff') && <a target="_self" href={`/Card/Sprint/${sprint.Id}/${blockType}`}><span className="iconSprintWhite verysmallIcons mr5"></span></a>}
          <span className="">{sprint.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnStatus(props) {
    const status = props.Status;

    // Status (1: To be Done, 2: In Progress, 3: On Hold, 4: Cancelled, 5: Completed, 6: Open, 7: Closed)
    // Status (8: Realised, 9: To be Scheduled, 10: Scheduled, 11: Taken, 12: To be Taken)
    if(status) {
      switch(status.Id) {
        case 1:
        case 9:
            return <div className="minitableAxe brd-radius bg-grey white">{status.Label}</div>;
        case 2:
        case 10:
            return <div className="minitableAxe brd-radius bg-blue white">{status.Label}</div>;
        case 3:
            return <div className="minitableAxe bordered orange">{status.Label}</div>;
        case 4:
            return <div className="minitableAxe bordered grey">{status.Label}</div>;
        case 5:
        case 7:
        case 11:
            return <div className="minitableAxe brd-radius bg-green white">{status.Label}</div>;
        case 6:
        case 12:
            return <div className="minitableAxe brd-radius bg-orange white">{status.Label}</div>;
        case 8:
            return <div className="minitableAxe brd-radius bg-red white">{status.Label}</div>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTask(props) {
    const task = props.Task;

    if(task) {
      if(task.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{task.Label}</div>;
      }
      else if(task.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/Task/${task.Id}/Details`}><span className="iconTask verysmallIcons mr5"></span></a>
          <span className="">{task.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTimeline(props) {
    let status = props.Status;
    let startDate = props.StartDate;
    let endDate = props.EndDate;

    // Create Timeline with different colors based on status, StartDate and EndDate
    if(status && startDate && endDate) {
      // Format Date to English format (it is Date object after modification in Table Datepicker)
      if(startDate instanceof Date) {
        startDate = startDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      if(endDate instanceof Date) {
        endDate = endDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }

      return <Timeline Display={'Dashboard'} Status={status} StartDate={startDate} EndDate={endDate}></Timeline>;
    }
    else {
      return <div className=""></div>;
    }
  }

  templateColumnTrend(props) {
    const trend = props.Trend;

    // Trend (0: None, 1: Down ⬊, 2: Right ⮕, 3: Up ⬈)
    if(trend) {
      switch(trend.Id) {
        case 1:
            return <span className="iconTrendDown iconsMiniblock"></span>;
        case 2:
            return <span className="iconTrendRight iconsMiniblock"></span>;
        case 3:
            return <span className="iconTrendUp iconsMiniblock"></span>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnValidated(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnWarning(props) {
    const warningMessage = props.WarningMessage;

    if(warningMessage) {
      return <OverlayTrigger key="right" placement="right" overlay={<Tooltip id="tooltip-right" className="tooltip-warning">{warningMessage}</Tooltip>}>
        <Badge className="badgeTable bg-white" pill><i className="iconWarningRed verysmallIcons"/></Badge>
      </OverlayTrigger>;
    }
    else {
      return null;
    }
  }

  templateColumnWorkpackage(props) {
    const workpackage = props.Workpackage;

    if(workpackage) {
      if(workpackage.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{workpackage.Label}</div>;
      }
      else if(workpackage.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/Workpackage/${workpackage.Id}/Home`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
          <span className="">{workpackage.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnYear(props) {
    const year = props.Year;

    if(year) {
      return <div className="">{year}</div>;
    }
    else {
      return null;
    }
  }

  // ----- ----- Type
  templateTypeAxe(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const value = props[columnName];

    let backColor, foreColor, borderColor;

    // Back, Fore & Border Color
    if(value) {
      if(!value.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = value.BackColor;
      }
      if(!value.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
          borderColor = value.BackColor;
        }
        else {
          borderColor = value.ForeColor;
        }

        foreColor = value.ForeColor;
      }
    }

    if(value) {
      if(value.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="minitableAxe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeAxisTable(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];

    if(values) {
      return <div className="displayblock">
        {values.map((value, index) => {
          let backColor, foreColor, borderColor;

          // Back, Fore & Border Color
          if(value) {
            if(!value.BackColor) {
              backColor = '#FFFFFF';
            }
            else {
              backColor = value.BackColor;
            }
            if(!value.ForeColor) {
              foreColor = '#0088C7';
              borderColor = '#0088C7';
            }
            else {
              if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                borderColor = value.BackColor;
              }
              else {
                borderColor = value.ForeColor;
              }

              foreColor = value.ForeColor;
            }
          }

          return <div key={index} className={"minitableAxe bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeBoolean(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeDate(props) {
    const formatDate = this.state.formatDate;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let date;

    if(value) {
      if(formatDate === 'MM/DD/YYYY') {
        date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      else if(formatDate === 'DD/MM/YYYY') {
        date = new Date(value).toLocaleDateString("fr-FR");
      }
      else if(formatDate === 'YYYY-MM-DD') {
        date = this.formatDateKr(new Date(value));
      }

      return <div className="">{date}</div>;
    }
    else {
      return <div className=""></div>;
    }
  }

  templateTypeDouble(props) {
    const dictColumns = this.state.dictColumns;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let conditionalFormattings, conditionalFormatting, unit;

    if(column) {
      conditionalFormattings = column.ConditionalFormattings;
      conditionalFormatting = this.getCellConditionalFormatting(conditionalFormattings, value);
      unit = column.Unit;
    }

    if(conditionalFormatting) {
      if(value || value === 0) {
        if(unit) {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      if(value || value === 0) {
        if(unit) {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
  }

  templateTypeFiles(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            if(value && value.Label) {
              let split = value.Label.split('.');
              let extension = split[split.length - 1];

              if(extension === 'docx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconWord iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pdf') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPDF iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pptx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPPT iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'xlsx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconExcel iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconFile iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
            }
            else {
              return null;
            }
          })}
        </div>
      </div>;
    }
    else {
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
      return null;
    }
  }

  templateTypeHTML(props) {
    const dictParameters = this.state.dictParameters;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    if(dictParameters['HTMLContent'] && dictParameters['HTMLContent'] === 'Limited') {
      return <div className="limitedHeightTable" dangerouslySetInnerHTML={{ __html: value }}/>;
    }
    else if(value) {
      return <div dangerouslySetInnerHTML={{ __html: value }}/>;
    }
    else {
      return null;
    }
  }

  templateTypeLink(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    const split = value.split('/');
    const shortName = split[split.length-1];

    if(value) {
      return <div title={value} className=""><a target="_self" className="" href={value}><span className="treeIcon iconLink iconsPath" alt="Link"></span>{shortName}</a></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLocation(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    if(value) {
      if(value.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="minitableLocation">
          <span className="iconMap verysmallIcons"></span>
          <span className="">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypePercentage(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let percentage = value;
    // const percentage = (value * 100).toFixed(0);

    if(value) {
      if(percentage == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 100 && percentage >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(percentage)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeProgress(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let progress;
    
    if(value) {
      progress = (value.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateTypeQRCode(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    const url = window.location.origin + '/Card/' + itemType + '/' + itemId.substring(1) + '/Details';

    if(itemId && itemType && url) {
      return <QRCode value={url} size={64} style={{ height: 'auto', width: 'auto' }} viewBox={`0 0 64 64`}/>;
    }

    // Get ColumnName and Value
    // const columnName = this.getColumnName(props);
    // const value = props[columnName];

    // if(value) {
    //   return <img style={{ height: '50px', width: '50px' }} src={'data:image/jpeg;base64,' + value}/>;
    // }
  }

  templateTypeRating(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    if(value == 5) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
      </div>;
    }
    else if(value == 4) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else if(value == 3) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else if(value == 2) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else if(value == 1) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else {
      return <div className="ratingTable">
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
  }

  templateTypeResource(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    if(value) {
      if(value.Id === 1) {
        let firstname = 'N';
        let lastname = 'A';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Label === '- To be assigned -') {
        let firstname = 'T';
        let lastname = 'B';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Id && value.Label) {
        let firstname, lastname;
        let split = value.Label.split(' ');

        if(split.length === 1) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[1].substring(0,1);
        }
        else {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[split.length-1].substring(0,1);
        }

        return <div className="align-baseline">
          <a target="_self" href={`/Card/Resource/${value.Id}/Home`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeResourceTable(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            let firstname, lastname;
            const split = value.Label.split(' ');
      
            if(split.length === 1) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[split.length-1].substring(0,1);
            }

            if(value.Id === 0) {
              return <div key={index} className="multiResource mr10">{value.Label}</div>;
            }
            else {
              return <div key={index} className="multiResource mr10">
                <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                <span className="ml5 grey">{value.Label}</span>
              </div>;
            }
          })}
        </div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeText(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    return <div className="">{value}</div>;
  }
  
  render() {
    let { language, itemId, itemType, blockType, display, editable, pagging, currentView, dictParameters, dictColumns, columns, dataSource } = this.state;
    let height;

    if(Object.entries(dictParameters).length === 0 || columns.length === 0 || dataSource.length === 0) {
      return <div className=""></div>;
    }

    if(display === 'Chart') {
      height = (window.innerHeight - 205);
    }
    else {
      height = '100%';
    }

    // Column Menu
    this.columnMenuItems = [];

    // Context Menu
    this.contextMenuItems = [];

    // Edit Options
    this.editOptions = { allowAdding: false, allowDeleting: false, allowEditing: false, mode: 'Cell' };
    
    // Grid Lines
    this.gridLines = 'None';

    return (
      <div className="control-pane height100p">
        <div className="control-section height100p">
          {/* Grid Component */}
          {dataSource.length > 0 && 
            <TreeGridComponent id={'table' + currentView.ViewId} dataSource={dataSource} idMapping='Item_ID' parentIdMapping='Parent_ID' expandStateMapping='isExpanded' locale={Traduction.translate(language, 'locale')} height={height} editSettings={this.editOptions} gridLines={this.gridLines} treeColumnIndex={this.getTreeColumnIndex(dictParameters['Columns'])} columnMenuItems={this.columnMenuItems} contextMenuItems={this.contextMenuItems} rowDataBound={this.rowDataBound} actionBegin={this.actionBegin} collapsing={this.collapsing} expanding={this.expanding} excelQueryCellInfo={this.excelQueryCellInfo} showColumnMenu={false} showColumnChooser={false} allowFiltering={false} allowPaging={pagging} pageSettings={{ pageSize: 50 }} allowExcelExport={true} allowReordering={false} allowResizing={true} allowSorting={false} allowTextWrap={true} ref={treegrid=>this.treegrid=treegrid}>
              <ColumnsDirective>
                {/* Create Current view Columns */}
                {dictParameters && dictParameters.Columns.map((column, index) => {
                  let col;

                  // Check if ColumnHeader is sent by backend
                  if(dictColumns[column]) {
                    col = this.createColumn(dictColumns[column], dictParameters['ColumnsWidth'], true);

                    return <ColumnDirective key={index} field={col.field} type={col.type} headerText={col.headerText} format={col.format} template={col.template} textAlign={col.textAlign} isPrimaryKey={col.isPrimaryKey} allowEditing={false} visible={true} width={col.width}></ColumnDirective>
                  }
                })}
                {/* Mandatory Columns if not in Current view */}
                {!dictParameters.Columns.find(column => column === 'Item_ID') && <ColumnDirective field='Item_ID' headerText={'Item_ID'} isPrimaryKey={true} allowEditing={false} visible={false}></ColumnDirective>}
                {!dictParameters.Columns.find(column => column === 'Item_Type') && <ColumnDirective field='Item_Type' headerText={'Item_Type'} isPrimaryKey={false} allowEditing={false} visible={false}></ColumnDirective>}
              </ColumnsDirective>
              <Inject services={[ ContextMenu, ColumnMenu, Edit, Filter, Sort, Reorder, Resize, Page, ExcelExport ]}/>
            </TreeGridComponent>
          }
        </div>
      </div>
    )
  }
}

export default MiniTree;